import React from 'react';
import {Pane, Text, Icon, FullCircleIcon, Tooltip, IconButton, RedoIcon} from "evergreen-ui";
import {Link, useNavigate} from 'react-router-dom'
import {DetailedReport} from "../../api";
import {LoadStore} from "../../store/load-store";
import {Loader} from "@aws-amplify/ui-react";
import {LoadGenPaneProps} from "../loadGen/LoadGenPane";

type Props = {
    detailedReport: DetailedReport;
    color?: string;
}

function ReportHeading(props: Props) {
    const loadStore = LoadStore.useContainer();
    const navigate = useNavigate();
    let colorToUse = props.detailedReport.status === 'SUCCESS' ? 'green' : props.detailedReport.status === 'IN_PROGRESS' ? 'yellow' : 'red'
    if (props.color) {
        colorToUse = props.color
    }

    const navigateToLoadGenerator = () => {
        const loadGenProps: LoadGenPaneProps = {
            accountWeAreTestingInName: props.detailedReport.accountWeAreTestingName || '',
            accountWeAreTestingInId: props.detailedReport.accountWeAreTestingId || '',
            instanceType: props.detailedReport.instanceType,
            loadTestName: props.detailedReport.loadTestName,
            availabilityZoneId: props.detailedReport.availabilityZoneId,
            configName: props.detailedReport.configName,
            authTokenPath: props.detailedReport.authTokenPath,
            durationInMinutes: props.detailedReport.durationInMinutes.toString(),
            cacheName: props.detailedReport.cacheName
        }
        navigate("/loadGenerator", {
            state: loadGenProps
        })
    }
    return (
        <Pane>
            <Pane marginTop={16} display='flex' flexDirection={"column"}>
                <Pane>
                    <Icon icon={FullCircleIcon} size={12} color={colorToUse} marginRight={10} />
                    <Link to={`/report/${props.detailedReport.id}`}>
                        <Text size={500} marginRight={8}>{props.detailedReport.loadTestName}: {props.detailedReport.id}</Text>
                    </Link>
                    {loadStore.isFetching ?
                        <Loader /> :
                        <Tooltip content={'Rerun load test'}>
                            <IconButton icon={RedoIcon} border={'none'} onClick={navigateToLoadGenerator} />
                        </Tooltip>
                    }
                </Pane>

                <Pane display={"flex"} flexDirection={"column"}>
                    <Text>Availability Zone Id: {props.detailedReport.availabilityZoneId}</Text>
                    <Text>Auth Token: {props.detailedReport.authTokenPath}</Text>
                    <Text>Cache Name: {props.detailedReport.cacheName}</Text>
                    <Text>Instance Type: {props.detailedReport.instanceType}</Text>
                    <Text>Operator: {props.detailedReport.operatorUser}</Text>
                </Pane>
                {/*<StatusBadge status={report1.status} />*/}
            </Pane>
        </Pane>
    );
}

export default ReportHeading;

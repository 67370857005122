import React from "react";
import {Amplify} from "aws-amplify";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import {withAuthenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Pane,
  Tab,
  Icon,
  Text,
  DashboardIcon,
  LockIcon,
  SettingsIcon,
  LightningIcon, StopIcon,
} from "evergreen-ui";
import {CognitoUser} from "amazon-cognito-identity-js";
import {LoadReportList} from "./components/reports/LoadReportList";
import LoadReport from './components/reports/LoadReport';
import ReportComparison from "./components/reports/ReportComparison";
import {ConfigList} from "./components/configs/ConfigsList";
import Config from "./components/configs/Config";
import {SecretsList} from "./components/secrets/SecretsList";
import {LoadGenPane} from "./components/loadGen/LoadGenPane";
import {CancelPane} from "./components/cancel/CancelPane";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN,
      scope: [
        'email',
        'openid',
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
      clientId: process.env.REACT_APP_CLIENT_ID,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS"
  },
});

export interface CognitoAttributes {
  email: string;
  phone_number: string;
  [key: string]: string;
}
/** Cognito User Interface */
export interface CognitoUserAmplify extends CognitoUser {
  username?: string;
  attributes?: CognitoAttributes;
}

type TabLinkProps = {
  to: string;
  icon: React.ElementType | JSX.Element;
  linkText: string;
}
const TabLink = (props: TabLinkProps) => (
    <Link to={props.to}  style={{ textDecoration: 'none' }}>
      <Tab height={36} paddingX={14}><Icon icon={props.icon} marginRight={12} /><Text size={400}>{props.linkText}</Text></Tab>
    </Link>
)

const App = () => {
  return (
      <Router>
        <Pane display='flex' paddingY={12} borderBottom>
          <Pane flex={1} alignItems='center' display='flex' marginLeft={8}>
            <TabLink to='/reports' linkText='REPORTS' icon={DashboardIcon} />
            <TabLink to='/secrets' linkText='SECRETS' icon={LockIcon} />
            <TabLink to='/configs' linkText='CONFIGS' icon={SettingsIcon} />
            <TabLink to='/loadGenerator' linkText='LOAD GENERATOR' icon={LightningIcon} />
            <TabLink to='/cancel' linkText='CANCEL' icon={StopIcon} />
          </Pane>
        </Pane>
        <Pane paddingX={20} paddingY={12}>
          <Routes>
            <Route path="/reports" element={<LoadReportList />} />
            <Route path="/report/:id" element={<LoadReport />} />
            <Route path="/report/:reportId1/:reportId2" element={<ReportComparison />} />
            <Route path="/configs" element={<ConfigList />} />
            <Route path="/config/:name" element={<Config />} />
            <Route path="/secrets" element={<SecretsList />} />
            <Route path="/loadGenerator" element={<LoadGenPane />} />
            <Route path="/cancel" element={<CancelPane />} />
            <Route path="/cancel/:id" element={<CancelPane />} />
            <Route
                path="*"
                element={<Navigate to="/reports" replace />}
            />
          </Routes>
        </Pane>
      </Router>
  );
};

export default withAuthenticator(App, { socialProviders: ['google'], hideSignUp: true });

export enum ApiRequestType {
    listReports = "LIST_REPORTS",
    getDetailedReport = "GET_DETAILED_REPORT",
    getDetailedReports = "GET_DETAILED_REPORTS",
    listConfigs = "LIST_CONFIGS",
    getConfig = "GET_CONFIG",
    uploadConfig = "UPLOAD_CONFIG",
    deleteConfig = "DELETE_CONFIG",
    listSecrets = "LIST_SECRETS",
    deleteSecret = "DELETE_SECRET",
    addSecret = "ADD_SECRET",
    runLoadTest = "RUN_LOAD_TEST",
    cancelLoadTest = "CANCEL_LOAD_TEST",
}

export interface RpcPerfDatum {
    timestamp: number,
    window: number,
    "commands/get": number,
    "commands/set": number,
    "connections/latency/p50": number,
    "connections/latency/p75": number,
    "connections/latency/p90": number,
    "connections/latency/p99": number,
    "connections/latency/p99.9": number,
    "connections/latency/p99.99": number,
    "connections/opened": number,
    "connections/total": number,
    "key/size/p50": number,
    "key/size/p75": number,
    "key/size/p90": number,
    "key/size/p99": number,
    "key/size/p99.9": number,
    "key/size/p99.99": number,
    "requests/dequeued": number,
    "requests/enqueued": number,
    "requests/unauthenticated": number,
    "requests/error": number,
    "responses/hit": number,
    "responses/latency/p50": number,
    "responses/latency/p75": number,
    "responses/latency/p90": number,
    "responses/latency/p99": number,
    "responses/latency/p99.9": number,
    "responses/latency/p99.99": number,
    "responses/miss": number,
    "responses/ok": number,
    "responses/total": number,
    "responses/invalid_argument": number,
    "responses/not_found": number,
    "responses/already_exists": number,
    "responses/permission_denied": number,
    "responses/resource_exhausted": number,
    "responses/failed_precondition": number,
    "responses/aborted": number,
    "responses/out_of_range": number,
    "responses/unimplemented": number,
    "responses/internal": number,
    "responses/unavailable": number,
    "responses/data_loss": number,
    "responses/error": number,
    "value/size/p50": number,
    "value/size/p75": number,
    "value/size/p90": number,
    "value/size/p99": number,
    "value/size/p99.9": number,
    "value/size/p99.99": number
}

type TestStatus = 'SUCCESS' | 'FAILED' | 'IN_PROGRESS' | 'CANCELLED';

export type ReportOverview = {
    id: string;
    loadTestName: string;
    startDate: string;
    endDate: string;
    status: TestStatus;
}

type PaginatedResponse = {
    nextToken?: string;
}

export interface ListReportsResponse extends PaginatedResponse {
    reports: ReportOverview[],
}

export type DetailedReport = {
    id: string;
    stats: RpcPerfDatum[];
    configFile: string;
    loadTestName: string;
    startDate: string;
    cacheName: string;
    instanceType: string;
    availabilityZoneId: string;
    authTokenPath: string;
    status: TestStatus;
    sfnExecutionId: string;
    operatorUser: string;
    durationInMinutes: number;
    configName: string;
    endpoint: string;
    region: string;
    accountWeAreTestingId?: string;
    accountWeAreTestingName?: string;
}

export interface ListConfigsResponse extends PaginatedResponse {
    configs: string[]
}

export interface ListSecretsResponse extends PaginatedResponse {
    secrets: string[]
}

export type GetConfigResponse = {
    config: string
}

export type UploadConfigResponse = {
    presignedUrl: string
}

export type StartLoadTestResponse = {
    loadTestId: string
}

export type StartLoadTestRequest = {
    type: ApiRequestType.runLoadTest,
    instanceType?: string;
    cacheName: string;
    region: string;
    availabilityZoneId: string;
    durationInMinutes?: number;
    accountWeAreTestingName?: string;
    accountWeAreTestingId?: string;
    operatorUser?: string;
    loadTestName: string;
    configName: string;
    authTokenPath: string;
}

export interface Iapi {
    listReports(nextToken?: string): Promise<ListReportsResponse>
    getDetailedReport(reportId: string): Promise<DetailedReport>
    listConfigs(nextToken?: string): Promise<ListConfigsResponse>
    getConfig(name: string): Promise<GetConfigResponse>
    uploadConfig(file: File): Promise<UploadConfigResponse>
    deleteConfig(configName: string): Promise<void>
    listSecrets(nextToken?: string): Promise<ListSecretsResponse>
    deleteSecret(secretName: string): Promise<void>
    addSecret(secretName: string, token: string): Promise<void>
    startLoadTest(request: StartLoadTestRequest): Promise<StartLoadTestResponse>
    cancelLoadTest(id: string): Promise<void>
}

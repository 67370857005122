import React, {ChangeEventHandler, useEffect, useState} from 'react'
import {
    Table,
    Heading,
    Pane,
    toaster,
    Pagination,
    Checkbox,
    IconButton,
    RemoveIcon,
    Tooltip,
    Button,
    ComparisonIcon, SearchInput
} from 'evergreen-ui'
import { Link, useNavigate } from 'react-router-dom'
import {ReportStore} from "../../store/report-store";
import {Loader} from "@aws-amplify/ui-react";
import {ReportOverview} from "../../api";
import {toLocaleString} from "../../lib/common";


export function LoadReportList() {
    const navigate = useNavigate();
    const reportsStore = ReportStore.useContainer();
    const [selectedReports, setSelectedReports] = useState<string[]>([])
    const [reportId, setReportId] = useState<string>()
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(1);
    const [reportsOnPage, setReportsOnPage] = useState<ReportOverview[]>([])

    useEffect(() => {
        if (reportsStore.reports.length >= pageSize) {
            setReportsOnPage(reportsStore.reports.slice(0, pageSize))
        } else {
            reportsStore.getReports().catch(e => {
                toaster.danger("failed to get reports", e)
                console.error(e)
            })
        }
    }, [])

    useEffect(() => {
        const desiredNumReports = (pageSize * page)
        const actualMaxNumReports = reportsStore.reports.length < desiredNumReports ? reportsStore.reports.length : desiredNumReports
        const actualStartingReportNum = actualMaxNumReports - pageSize
        setReportsOnPage(reportsStore.reports.slice(actualStartingReportNum, actualMaxNumReports))
    }, [reportsStore.reports, pageSize, page])

    const onNextPageClick = async () => {
        const newPage = page+1
        console.log("setting new page", newPage, reportsStore.hasMoreReports)
        setPage(newPage);
        const desiredNumReports = (pageSize * newPage)
        if (reportsStore.reports.length < desiredNumReports && reportsStore.hasMoreReports) {
            await reportsStore.getReports(true).catch(e => {
                toaster.danger("failed to get reports", e)
                console.error(e)
            })
        }
    }

    const onPreviousPageClick = async () => {
        const newPage = page-1
        console.log("setting new page", newPage)
        setPage(newPage)
        setReportsOnPage(reportsStore.reports.slice(newPage, newPage * pageSize))
    }

    if (reportsStore.isFetching) {
        return <Pane>
            <Loader />
        </Pane>
    }

    const onCheckboxChanged = (id: string, isChecked: boolean) => {
        if (!isChecked) {
            setSelectedReports(prev => prev.filter(id => id !== id))
            return
        }
        if (selectedReports.length == 2) {
            toaster.danger("Can only select 2 reports to compare, deselect")
            return
        }
        if (selectedReports.includes(id)) {
            return // this should never happen
        }
        setSelectedReports(prev => [id, ...prev])
    }

    const handleSearchInputSubmit = () => {
        if (reportId) {
            navigate(`/report/${reportId}`)
        }
    }

    return (
        <Pane>
            <form onSubmit={handleSearchInputSubmit}>
                <SearchInput
                    placeholder={"cf779026-aa8a-46ae-88ad-71ae2cc454c4"}
                    // @ts-ignore
                    onChange={(e) => setReportId(e.target.value)}
                />
            </form>
            <Pane display='flex' alignItems='center' marginTop={0}>
                <Pane flex={1}>
                    <Heading size={600}>REPORTS</Heading>
                </Pane>
                <Pane display={'flex'} alignItems={'center'}>

                    <Button
                        iconBefore={ComparisonIcon}
                        appearance='minimal'
                        intent='none'
                        disabled={selectedReports.length !== 2}
                        onClick={() => navigate(`/report/${selectedReports[0]}/${selectedReports[1]}`)}
                        marginRight={12}
                    >
                        COMPARE
                    </Button>
                    <Pagination
                        page={page}
                        totalPages={reportsStore.hasMoreReports ? Math.floor(reportsStore.reports.length/pageSize) + 1 : Math.floor(reportsStore.reports.length/pageSize)}
                        onNextPage={onNextPageClick}
                        onPreviousPage={onPreviousPageClick}
                    />
                </Pane>
            </Pane>

            <Table marginY={20}>
                <Table.Head>
                    <Table.TextHeaderCell maxWidth={40}>
                        <Tooltip content={"clear selections"}>
                            <IconButton icon={RemoveIcon} height={20} disabled={selectedReports.length !== 2} onClick={() => setSelectedReports([])} />
                        </Tooltip>
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell maxWidth={280} textProps={{ size: "400" }}>
                        <Pane display='flex'>
                            ID
                        </Pane>
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell minWidth={280} textProps={{ size: "400" }}>
                        {/* TODO make this sort by date*/}
                        <Pane display='flex'>
                            Start Date (PST)
                        </Pane>
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell maxWidth={200} textProps={{ size: "400" }}>
                        Name
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell maxWidth={120} textProps={{ size: "400" }}>
                        Status
                    </Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                    {reportsOnPage.map(report => {
                        return (
                            <Table.Row key={report.id}>
                                <Table.TextCell maxWidth={40}>
                                    <Checkbox
                                        name={`chk-${report.id}`}
                                        onChange={e => onCheckboxChanged(report.id, e.target.checked)}
                                        checked={selectedReports.includes(report.id)}
                                    />
                                </Table.TextCell>
                                <Table.TextCell maxWidth={280} >
                                    <Link to={`/report/${report.id}`}>{report.id}</Link>
                                </Table.TextCell>
                                <Table.TextCell minWidth={280}>
                                    <Pane display={'flex'}>
                                        {toLocaleString(report.startDate)}
                                    </Pane>
                                </Table.TextCell>
                                <Table.TextCell maxWidth={200}>
                                    <Pane>
                                        {report.loadTestName}
                                    </Pane>
                                </Table.TextCell>
                                <Table.TextCell maxWidth={120}>
                                    <Pane>
                                        {report.status}
                                    </Pane>
                                </Table.TextCell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
                <Pane justifyContent='right' marginTop={10} display='flex'>

                </Pane>
            </Table>
        </Pane>
    )
}


import React, {useEffect} from 'react';
import {Pane, Heading, Pre} from "evergreen-ui";
import { useParams } from 'react-router-dom'
import {Loader} from "@aws-amplify/ui-react";
import {ConfigStore} from "../../store/config-store";
import {desanatizeRoute} from "../../lib/common";

type Params = {
    name: string;
}

function Config() {
    const configStore = ConfigStore.useContainer();
    const params = useParams<Params>();
    console.log("params", params);
    useEffect(() => {
        if (params.name) {
            configStore.getConfig(desanatizeRoute(params.name)).catch(e => console.error(e))
        } else {
            // navigate to 404
        }
    }, [])

    if (!configStore.selectedConfig || !params.name) {
        return (
            <Pane>
                <Loader />
            </Pane>
        )
    }
    return (
        <Pane>
            <Heading size={600}>
                Config: {desanatizeRoute(params.name)}
            </Heading>
            <Pane background='tint2' marginTop={16}>
                <Pre fontFamily='monospace' paddingX={16} paddingY={16}>
                    {configStore.selectedConfig}
                </Pre>
            </Pane>
        </Pane>
    );
}

export default Config;

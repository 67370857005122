import {DetailedReport} from "../../api";
import {createDonutChartDatasetFromRpcPerfData} from "../../lib/common";
import {Heading, Pane, Table, Text, Tooltip} from "evergreen-ui";
import {Doughnut} from "react-chartjs-2";
import React from "react";

type StatusCodeChartProps = {
    data: DetailedReport
}
function StatusCodeChart(props: StatusCodeChartProps) {
    const chartData = createDonutChartDatasetFromRpcPerfData(props.data)
    return (
        <Pane display='flex' marginTop={16} alignItems='left'>
            <Pane minWidth={400} maxWidth={500} alignItems='left'>
                <Heading size={600}>
                    Status Code Distribution
                </Heading>
                <Doughnut data={chartData.dataset} options={{
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                color: 'rgb(255, 99, 132)'
                            },
                            position: 'bottom'
                        }
                    },
                }} />
            </Pane>
            <Pane flex={1} marginLeft={16} maxHeight={400} overflowY={'auto'}>
                {Object.entries(chartData.statusCodeDistribution).map(([key, value]) => (
                    <Table.Row key={`status-${key}`}>
                        <Table.TextCell textProps={{ size: 400 }} minWidth={200}>
                            {key}
                        </Table.TextCell>
                        <Table.TextCell isNumber>
                            {value.toString()}
                            <Tooltip content='Percent of all calls'>
                                <Text marginLeft={8} textDecoration='underline dotted'>
                                    {'(' + Number.parseFloat(String((value / chartData.totalRequests) * 100)).toFixed(2) + ' %)'}
                                </Text>
                            </Tooltip>
                        </Table.TextCell>
                    </Table.Row>
                ))}
            </Pane>
            <Pane flex={1} />
        </Pane>
    )
}

export default StatusCodeChart;

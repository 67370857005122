import React from 'react';
import {Pane, Heading, Pre} from "evergreen-ui";
import {DetailedReport} from "../../api";

type Props = {
    detailedReport: DetailedReport;
    color?: string;
}

function ConfigFile(props: Props) {
    return (
        <Pane overflowX={'auto'}>
            <Heading size={600} color={props.color}>
                Config: {props.detailedReport.configName}
            </Heading>
            <Pane background='tint2' marginTop={16} overflowX={'auto'}>
                <Pre fontFamily='monospace' paddingX={16} paddingY={16}>
                    {props.detailedReport.configFile}
                </Pre>
            </Pane>
        </Pane>
    );
}

export default ConfigFile;

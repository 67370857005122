import React, { useState } from "react"
import { createContainer } from "unstated-next"
import {toaster} from "evergreen-ui";
import {api} from '../api';

function useConfigStore() {
    const [isFetching, setIsFetching] = useState(false);
    const [configs, setConfigs] = useState<string[]>([]);
    const [nextConfigsToken, setNextConfigsToken] = useState<string | undefined>(undefined);
    const [selectedConfig, setSelectedConfig] = useState<string>()

    const listConfigs = async (fetchNext?: boolean) => {
        setIsFetching(true);
        try {
            if (fetchNext) {
                const resp = await api.listConfigs(nextConfigsToken)
                setConfigs((currConfigs) => [...currConfigs, ...resp.configs])
                setNextConfigsToken(resp.nextToken)
            } else {
                const resp = await api.listConfigs();
                setConfigs(resp.configs)
                setNextConfigsToken(resp.nextToken)
            }
        } catch (e) {
            toaster.danger(`failed to list configs: ${e}`)
        } finally {
            setIsFetching(false)
        }
    }

    const getConfig = async (name: string) => {
        setIsFetching(true)
        try {
            const resp = await api.getConfig(name);
            setSelectedConfig(resp.config)
        } catch (e) {
            setSelectedConfig(undefined);
            toaster.danger(`failed to get config: ${e}`)
        } finally {
            setIsFetching(false);
        }
    }

    const deleteConfig = async (name: string) => {
        setIsFetching(true)
        try {
            await api.deleteConfig(name);
            setConfigs((prevConfigs) => prevConfigs.filter(configName => configName !== name))
        } catch (e) {
            toaster.danger(`failed to get config: ${e}`)
        } finally {
            setIsFetching(false);
        }
    }

    const uploadConfig = async (file: File) => {
        setIsFetching(true)
        try {
            const resp = await api.uploadConfig(file);
            const requestOptions = {
                method: 'PUT',
                body: file,
            };
            // TODO need to update bucket CORS policy to allow origins
            await fetch(resp.presignedUrl, requestOptions);
            await listConfigs();
        } catch (e) {
            toaster.danger(`failed to upload config: ${e}`)
        } finally {
            setIsFetching(false);
        }
    }

    const hasMoreConfigs = !!nextConfigsToken
    return { configs, isFetching, listConfigs, selectedConfig, getConfig, hasMoreConfigs, uploadConfig, deleteConfig }
}

export const ConfigStore = createContainer(useConfigStore)

import {DetailedReport} from "../api";

export const ONE_MILLION = 1000000;

type DoughnutChartDataset = {
    label: string;
    data: number[];
    backgroundColor: string[];
}
type DoughnutChartProps = {
    labels: string[];
    datasets: DoughnutChartDataset[]
}

type LineChartDataset = {
    label: string;
    data: number[];
    fill: boolean;
    borderColor: string;
    tension: number;
}

type LineChartProps = {
    labels: string[];
    datasets: LineChartDataset[]
}

type HistogramChartDataset = {
    type: "bar";
    label: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    data: number[]
}

type HistogramChartProps = {
    labels: string[];
    datasets: HistogramChartDataset[]
}

type StatusCodeDistribution = {
    dataLoss: number;
    outOfRange: number;
    unimplemented: number;
    internal: number;
    unavailable: number;
    notFound: number;
    alreadyExists: number;
    permissionDenied: number;
    failedPrecondition: number;
    aborted: number;
    miss: number;
    ok: number;
    hit: number;
    invalidArgument: number;
    resourceExhausted: number;
    error: number;
}

export type MomentoDoughnutChart = {
    dataset: DoughnutChartProps;
    totalRequests: number;
    statusCodeDistribution: StatusCodeDistribution;
}

const getStatusCodeDistributionFromReport = (report: DetailedReport): StatusCodeDistribution => {
    const lastElement = report.stats.slice(-1)[0];
    return {
        ok: lastElement["responses/ok"],
        hit: lastElement["responses/hit"],
        miss: lastElement["responses/miss"],
        failedPrecondition: lastElement["responses/failed_precondition"],
        aborted: lastElement["responses/aborted"],
        alreadyExists: lastElement["responses/already_exists"],
        outOfRange: lastElement["responses/out_of_range"],
        invalidArgument: lastElement["responses/invalid_argument"],
        internal: lastElement["responses/internal"],
        dataLoss: lastElement["responses/data_loss"],
        unavailable: lastElement["responses/unavailable"],
        unimplemented: lastElement["responses/unimplemented"],
        notFound: lastElement["responses/not_found"],
        permissionDenied: lastElement["responses/permission_denied"],
        resourceExhausted: lastElement["responses/resource_exhausted"],
        error: lastElement["responses/error"],
    }
}

export const createDonutChartDatasetFromRpcPerfData = (report: DetailedReport): MomentoDoughnutChart => {
    const reportSummary = createReportSummary(report);
    const scd = reportSummary.statusCodeDistribution;
    const doughnutChartDataset: DoughnutChartProps = {
        labels: ['hit', 'miss', 'failed_precondition', 'aborted', 'already_exists', 'out_of_range', 'invalid_argument', 'internal', 'data_loss', 'unavailable', 'unimplemented', 'not_found', 'permission_denied', 'resource_exhausted', "error"],
        datasets: [{
            label: 'Status Codes',
            data: [scd.hit, scd.miss, scd.failedPrecondition, scd.aborted, scd.alreadyExists, scd.outOfRange, scd.invalidArgument, scd.internal, scd.dataLoss, scd.unavailable, scd.unimplemented, scd.notFound, scd.permissionDenied, scd.resourceExhausted, scd.error],
            backgroundColor: ['green', 'orange', 'blue', 'yellow', 'purple', 'cyan', 'magenta', 'lime', 'pink', 'black', 'beige', 'lavender', 'maroon', 'navy', 'red']
        }]
    }

    return {
        statusCodeDistribution: scd,
        totalRequests: reportSummary.totalRequests,
        dataset: doughnutChartDataset
    };
}

export function sanatizeRoute(routePath: string): string {
    return routePath.replaceAll("/", "%%%%")
}

export function desanatizeRoute(routePath: string): string {
    return routePath.replaceAll("%%%%", "/")
}

export function generateXAxisLabels(items: number[], n: number): string[] {
    if (items.length < n) {
        return Array.from(Array(items.length).keys()).map(it => String(it))
    }
    let elements = [items[0]];
    let totalItems = items.length - 2;
    let interval = Math.floor(totalItems/(n - 2));
    for (let i = 1; i < n - 1; i++) {
        elements.push(items[i * interval]);
    }
    elements.push(items[items.length - 1]);
    return elements.map(it => String(it));
}

export type ReportSummary = {
    totalRequests: number;
    statusCodeDistribution: StatusCodeDistribution
}

export const createReportSummary = (report: DetailedReport): ReportSummary => {
    const lastElement = report.stats.slice(-1)[0];
    return {
        totalRequests: lastElement["responses/total"],
        statusCodeDistribution: getStatusCodeDistributionFromReport(report)
    };
}

export const generateLatencyLineChartProps = (reports: Array<DetailedReport>, borderColors: string[], monotoneColors?: string[]): LineChartProps => {
    const metricsWeCareAbout = ["responses/latency/p50", "responses/latency/p90", "responses/latency/p99", "responses/latency/p99.9", "responses/latency/p99.99"]
    const numArray = Array.from(Array(reports[0].stats.length).keys())
    const labels = generateXAxisLabels(numArray, 2000);
    const datasets: LineChartDataset[] = reports.flatMap((report, reportIndex) => {
        return metricsWeCareAbout.map((metric, i) => {
            let colorToUse = borderColors[i % borderColors.length]
            if (monotoneColors && monotoneColors[reportIndex]) {
                colorToUse = monotoneColors[reportIndex]
            }
            return {
                label: metric.split("/")[2],
                // @ts-ignore
                data: report.stats.map(d => d[metric]/ONE_MILLION),
                fill: false,
                borderColor: colorToUse,
                tension: 0.1
            }
        })
    })
    return {
        labels,
        datasets: datasets
    }
}

// export const createComparisonLineChartProps = (report1: DetailedReport, report2: DetailedReport): LineChartProps => {
//     const numArray = Array.from(Array(report1.stats.length).keys())
//     const labels = generateXAxisLabels(numArray, 2000);
//     const report1Color = "blue";
//     const report2Color = "orange";
//     const report1Datasets = generateLatencyLineChartProps(report1, [report1Color]);
//     const report2Datasets = generateLatencyLineChartProps(report2, [report2Color])
//     return {
//         labels,
//         datasets: [
//             ...report1Datasets,
//             ...report2Datasets
//         ]
//     }
// }

export function toLocaleString (date: string | Date | undefined) {
    if (!date) {
        return '';
    }
    if (date instanceof Date) {
        return date.toLocaleString
    }

    const dateObj = new Date(date + '')
    return dateObj.toLocaleString()
}

import React, {ChangeEvent, useEffect, useState} from 'react'
import {
    Pane,
    Button, TextInputField, Heading,
} from 'evergreen-ui'
import {LoadStore, StoreStartLoadTestRequest} from "../../store/load-store";
import {useLocation, useNavigate} from "react-router-dom";
import {Loader} from "@aws-amplify/ui-react";

export type LoadGenPaneProps = {
    loadTestName: string;
    cacheName: string;
    availabilityZoneId: string;
    instanceType: string;
    durationInMinutes: string;
    configName: string;
    authTokenPath: string;
    accountWeAreTestingInName: string;
    accountWeAreTestingInId: string;
}

export function LoadGenPane() {
    const navigate = useNavigate();
    const loadStore = LoadStore.useContainer();
    const props = useLocation().state as LoadGenPaneProps | undefined;
    const [loadTestName, setLoadTestName] = useState<string>(props?.loadTestName ?? "")
    const [cacheName, setCacheName] = useState<string>(props?.cacheName ?? "")
    const [region, setRegion] = useState<string>("us-west-2")
    const [availabilityZoneId, setAvailabilityZoneId] = useState<string>(props?.availabilityZoneId ?? "")
    const [instanceType, setInstanceType] = useState<string>(props?.instanceType ?? "c6i.4xlarge")
    const [durationInMinutes, setDurationInMinutes] = useState<string>(props?.durationInMinutes ?? "60")
    const [configName, setConfigName] = useState<string>(props?.configName ?? "")
    const [authTokenPath, setAuthTokenPath] = useState<string>(props?.authTokenPath ?? "")
    const [accountWeAreTestingName, setAccountWeAreTestingName] = useState<string>(props?.accountWeAreTestingInName ?? "")
    const [accountWeAreTestingId, setAccountWeAreTestingId] = useState<string>(props?.accountWeAreTestingInId ?? "")
    const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false)

    useEffect(() => {
        if (!accountWeAreTestingId && !accountWeAreTestingName) {
            setIsSubmitButtonEnabled(false);
            return
        }
        if (!loadTestName || ! cacheName || !region || !availabilityZoneId || !instanceType || !durationInMinutes || !configName || !authTokenPath) {
            setIsSubmitButtonEnabled(false);
            return
        }
        setIsSubmitButtonEnabled(true)
    }, [durationInMinutes, authTokenPath, accountWeAreTestingName, accountWeAreTestingId, instanceType, region, cacheName, loadTestName, configName, availabilityZoneId])

    const startLoadTest = async () => {
        const request: StoreStartLoadTestRequest = {
            authTokenPath: authTokenPath,
            availabilityZoneId: availabilityZoneId,
            cacheName: cacheName,
            configName: configName,
            loadTestName: loadTestName,
            region: region,
            instanceType: instanceType,
            accountWeAreTestingName: accountWeAreTestingName,
            accountWeAreTestingId: accountWeAreTestingId,
            durationInMinutes: parseInt(durationInMinutes)
        }
        const resp = await loadStore.startLoadTest(request);
        if (resp.isSuccess) {
            navigate("/reports", { replace: true })
        }
    }

    return (
        <Pane>
            <Heading>*Note: perf tests are only supported in us-west-2*</Heading>
            <Pane display={'flex'} maxWidth={800} flexWrap={'wrap'} marginTop={12}>
                <TextInputField
                    value={loadTestName}
                    id={"load-test-name"}
                    width={200}
                    required={true}
                    label="Load Test Name"
                    placeholder="matt_load_test_against_dev_cell"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setLoadTestName(event.target.value.replaceAll(" ", ""))}
                    marginRight={8}
                />
                <TextInputField
                    value={cacheName}
                    id={"cache-name"}
                    width={200}
                    required={true}
                    label="Cache Name"
                    placeholder="loadtest-cache"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setCacheName(event.target.value)}
                    marginRight={8}
                />
                <TextInputField
                    value={availabilityZoneId}
                    id={"availability-zone-id"}
                    width={200}
                    required={true}
                    label="Availability Zone Id"
                    placeholder="usw2-az1"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setAvailabilityZoneId(event.target.value)}
                    marginRight={8}
                />
                <TextInputField
                    value={configName}
                    id={"config-name"}
                    width={200}
                    required={true}
                    label="Config Name"
                    placeholder="rpc-perf/sample_config.toml"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setConfigName(event.target.value)}
                    marginRight={8}
                />
                <TextInputField
                    value={authTokenPath}
                    id={"auth-token-path"}
                    width={200}
                    required={true}
                    label="Auth Token Path"
                    placeholder="perftesting/matt/cache/docker"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setAuthTokenPath(event.target.value)}
                    marginRight={8}
                />
                <TextInputField
                    value={instanceType}
                    id={"instance-type"}
                    width={200}
                    required={false}
                    label="Instance Type"
                    placeholder="c6i.4xlarge"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setInstanceType(event.target.value)}
                    marginRight={8}
                />
                <TextInputField
                    value={durationInMinutes}
                    id={"duration-in-minutes"}
                    width={200}
                    required={false}
                    label="Duration In Minutes"
                    placeholder="60"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setDurationInMinutes(event.target.value)}
                    marginRight={8}
                />
                <TextInputField
                    value={accountWeAreTestingName}
                    id={"account-name"}
                    width={200}
                    required={false}
                    label="Account Name"
                    placeholder="cell-alpha"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setAccountWeAreTestingName(event.target.value)}
                    marginRight={8}
                />
                <TextInputField
                    value={accountWeAreTestingId}
                    id={"account-id"}
                    width={200}
                    required={false}
                    label="Account Id"
                    placeholder="561152405254"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setAccountWeAreTestingId(event.target.value)}
                    marginRight={8}
                />
            </Pane>

            {loadStore.isFetching ?
                <Loader /> :
                <Button
                    onClick={
                        async () => {
                            await startLoadTest();
                        }
                    }
                    disabled={!isSubmitButtonEnabled}
                >
                    Submit
                </Button>
            }
        </Pane>
    )
}


import React, {useEffect, useState} from 'react'
import {
    Table,
    Heading,
    Pane,
    toaster,
    Pagination,
    IconButton,
    TrashIcon, ClipboardIcon, Tooltip
} from 'evergreen-ui'
import { Link } from 'react-router-dom'
import {Loader} from "@aws-amplify/ui-react";

type Props = {
    onListItems: (nextToken?: boolean) => Promise<any>
    items: Array<string>
    hasMoreItems: boolean
    isFetching: boolean
    header: string
    buttonLeftOfPagination: React.ReactNode
    onDelete: (item: string) => Promise<void>
    onLinkTo?: (item: string) => string
}

export function PaginatedListOfItems(props: Props) {
    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(1);
    const [itemsOnPage, setItemsOnPage] = useState<string[]>([])

    useEffect(() => {
        if (props.items.length >= pageSize) {
            setItemsOnPage(props.items.slice(0, pageSize))
        } else {
            console.log("fdsfa")
            props.onListItems().catch(e => {
                toaster.danger("failed to list items", e)
                console.error(e)
            })
        }
    }, [])

    useEffect(() => {
        const desiredNumItems = (pageSize * page)
        const actualMaxNumItems = props.items.length < desiredNumItems ? props.items.length : desiredNumItems
        const actualStartingItemNum = actualMaxNumItems - pageSize
        console.log("test", actualMaxNumItems, actualStartingItemNum)
        setItemsOnPage(props.items.slice(actualStartingItemNum > 0 ? actualStartingItemNum : 0, actualMaxNumItems))
    }, [props.items, pageSize, page])

    const onNextPageClick = async () => {
        const newPage = page+1
        setPage(newPage);
        const desiredNumItems = (pageSize * newPage)
        if (props.items.length < desiredNumItems && props.hasMoreItems) {
            await props.onListItems(true).catch(e => {
                toaster.danger("failed to list next items", e)
                console.error(e)
            })
        }
    }

    const onPreviousPageClick = async () => {
        const newPage = page-1
        console.log("setting new page", newPage)
        setPage(newPage)
        setItemsOnPage(props.items.slice(newPage, newPage * pageSize))
    }

    const copyToClipboardFunction = async (text: string) => {
        await navigator.clipboard.writeText(text)
        toaster.success(`copied ${text} to clipboard`, {
            duration: 1
        })
    }

    if (props.isFetching) {
        return <Pane>
            <Loader />
        </Pane>
    }

    return (
        <Pane>
            <Pane display='flex' alignItems='center' marginTop={0}>
                <Pane flex={1}>
                    <Heading size={600}>{props.header}</Heading>
                </Pane>
                <Pane display={'flex'} alignItems={'center'}>
                    {props.buttonLeftOfPagination}
                    <Pagination
                        page={page}
                        totalPages={props.hasMoreItems ? Math.floor(props.items.length/pageSize) + 1 : Math.floor(props.items.length/pageSize)}
                        onNextPage={onNextPageClick}
                        onPreviousPage={onPreviousPageClick}
                    />
                </Pane>
            </Pane>

            <Table marginY={20}>
                <Table.Head>
                    <Table.TextHeaderCell textProps={{ size: "400" }}>
                        <Pane display='flex'>
                            Name
                        </Pane>
                    </Table.TextHeaderCell>
                </Table.Head>
                <Table.Body>
                    {itemsOnPage.map(item => {
                        return (
                            <Table.Row key={item}>
                                <Table.TextCell>
                                    {props.onLinkTo ?
                                        <Pane>
                                            <Link to={props.onLinkTo(item)}>
                                                {item}
                                            </Link>
                                            <Tooltip content={'Copy to clipboard'}>
                                                <IconButton marginLeft={8} icon={ClipboardIcon} border={'none'} onClick={async () => await copyToClipboardFunction(item)} />
                                            </Tooltip>
                                        </Pane>
                                         :
                                        <Pane>
                                            {item}
                                            <Tooltip content={'Copy to clipboard'}>
                                                <IconButton marginLeft={8} icon={ClipboardIcon} border={'none'} onClick={async () => await copyToClipboardFunction(item)} />
                                            </Tooltip>
                                        </Pane>
                                    }
                                </Table.TextCell>
                                <Table.TextCell maxWidth={80}>
                                    <IconButton icon={TrashIcon} onClick={() => props.onDelete(item)} />
                                </Table.TextCell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
                <Pane justifyContent='right' marginTop={10} display='flex' />
            </Table>
        </Pane>
    )
}


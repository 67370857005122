import React from 'react';
import ReactDOM from 'react-dom';
import {Chart, ArcElement, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip} from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ReportStore} from "./store/report-store";
import {ConfigStore} from "./store/config-store";
import {ComparisonStore} from "./store/comparison-store";
import {SecretStore} from "./store/secret-store";
import {LoadStore} from "./store/load-store";

Chart.register(
    ArcElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Tooltip,
    zoomPlugin
);

ReactDOM.render(
    <React.StrictMode>
        <LoadStore.Provider>
            <SecretStore.Provider>
                <ConfigStore.Provider>
                    <ReportStore.Provider>
                        <ComparisonStore.Provider>
                            <App />
                        </ComparisonStore.Provider>
                    </ReportStore.Provider>
                </ConfigStore.Provider>
            </SecretStore.Provider>
        </LoadStore.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {useState} from 'react'
import {
    Pane,
    Button,
    Popover,
    FileUploader,
    FileCard
} from 'evergreen-ui'
import {ConfigStore} from "../../store/config-store";
import {PaginatedListOfItems} from "../shared/PaginatedListOfItems";
import {sanatizeRoute} from "../../lib/common";

export function ConfigList() {
    const configsStore = ConfigStore.useContainer();
    const [file, setFile] = useState<File>()

    return (
        <Pane>
            <PaginatedListOfItems
                onListItems={configsStore.listConfigs}
                items={configsStore.configs}
                hasMoreItems={configsStore.hasMoreConfigs}
                isFetching={configsStore.isFetching}
                onLinkTo={(name: string) => `/config/${sanatizeRoute(name)}`}
                header={'CONFIGS'}
                buttonLeftOfPagination={
                    <Popover content={
                        <FileUploader
                            style={{ margin: '10px', padding: '10px' }}
                            label={"Upload Config"}
                            description="You can upload 1 file. File can be up to 1 MB."
                            maxSizeInBytes={1 * 1024 ** 2}
                            maxFiles={1}
                            onChange={async (files) => {
                                setFile(files[0])
                                await configsStore.uploadConfig(files[0])
                            }}
                            renderFile={(file) => {
                                const { name, size, type } = file
                                return (
                                    <FileCard
                                        key={name}
                                        name={name}
                                        sizeInBytes={size}
                                        type={type}
                                        isLoading={configsStore.isFetching}
                                    />
                                )
                            }}
                            values={file ? [file] : []}
                        />
                    }>
                        <Button>Upload Config</Button>
                    </Popover>
                }
                onDelete={async (name: string) => {
                    await configsStore.deleteConfig(name)
                }}
            />
        </Pane>
    )
}


import React, { useState } from "react"
import { createContainer } from "unstated-next"
import {toaster} from "evergreen-ui";
import {api} from '../api';
import {DetailedReport  } from "../api";

type ReportsToCompareProps = {
    reportId1: string;
    reportId2: string;
}

type ComparedReports = {
    report1: DetailedReport;
    report2: DetailedReport;
}
function useComparisonStore() {
    const [isFetching, setIsFetching] = useState(false);
    const [comparedReports, setComparedReports] = useState<ComparedReports>()

    const compareReports = async (props: ReportsToCompareProps) => {
        setIsFetching(true);
        try {
            const report1 = await api.getDetailedReport(props.reportId1);
            const report2 = await api.getDetailedReport(props.reportId2);
            console.log("set compared reports", report1, report2);
            setComparedReports({
                report1,
                report2
            })
        } catch (e) {
            toaster.danger(`failed to get reports: ${e}`)
        } finally {
            console.log("set compared reports", comparedReports)
            setIsFetching(false)
        }
    }
    return { compareReports, isFetching, comparedReports }
}

export const ComparisonStore = createContainer(useComparisonStore)

import React from 'react';
import {Pane, Text, Heading, Table, Tooltip, Strong} from "evergreen-ui";
import {DetailedReport} from "../../api";

type Props = {
    detailedReports: Array<DetailedReport>;
    colors: Array<string>;
}

function ReportSummaryTable(props: Props) {
    const maxWidthLabel = 100;
    return (
        <Pane display='flex' marginTop={24} marginBottom={24}>

            <Pane flex={2}>
                <Heading size={600}>
                    Report Summary
                </Heading>

                <Pane>
                    <Table.Row>
                        <Table.TextCell maxWidth={maxWidthLabel} />
                        {props.detailedReports.map((report, i) => {
                            return <Table.TextCell>
                                <Tooltip content={report.loadTestName}>
                                    <Text size={500} color={props.colors[i]}>{report.id}</Text>
                                </Tooltip>
                            </Table.TextCell>
                        })}
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell maxWidth={maxWidthLabel}>
                            <Strong>Requests</Strong>
                        </Table.TextCell>
                        {props.detailedReports.map(report => {
                            return <Table.TextCell isNumber>
                                {report.stats.slice(-1)[0]["requests/enqueued"]}
                            </Table.TextCell>
                        })}
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell maxWidth={maxWidthLabel}>
                            <Strong>Responses</Strong>
                        </Table.TextCell>
                        {props.detailedReports.map(report => {
                            return <Table.TextCell isNumber>
                                {report.stats.slice(-1)[0]["responses/total"]}
                            </Table.TextCell>
                        })}
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell maxWidth={maxWidthLabel}>
                            <Strong>Hit</Strong>
                        </Table.TextCell>
                        {props.detailedReports.map(report => {
                            return <Table.TextCell isNumber>
                                {report.stats.slice(-1)[0]["responses/hit"]}
                            </Table.TextCell>
                        })}
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell maxWidth={maxWidthLabel}>
                            <Strong>Miss</Strong>
                        </Table.TextCell>
                        {props.detailedReports.map(report => {
                            return <Table.TextCell isNumber>
                                {report.stats.slice(-1)[0]["responses/miss"]}
                            </Table.TextCell>
                        })}
                    </Table.Row>
                    <Table.Row>
                        <Table.TextCell maxWidth={maxWidthLabel}>
                            <Strong>Error</Strong>
                        </Table.TextCell>
                        {props.detailedReports.map(report => {
                            return <Table.TextCell isNumber>
                                {report.stats.slice(-1)[0]["requests/error"]}
                            </Table.TextCell>
                        })}
                    </Table.Row>
                </Pane>
            </Pane>

            <Pane flex={1} />
        </Pane>
    );
}

export default ReportSummaryTable;

import React, {useEffect} from 'react';
import {Pane, Text} from "evergreen-ui";
import {useParams} from 'react-router-dom'
import {ReportStore} from "../../store/report-store";
import {Loader} from "@aws-amplify/ui-react";
import ReportHeading from "./ReportHeading";
import LatencyChart from "./LatencyChart";
import StatusCodeChart from "./StatusCodeChart";
import ConfigFile from "./ConfigFile";

type Params = {
    id: string;
}

function LoadReport() {
    const reportStore = ReportStore.useContainer();
    const params = useParams<Params>();
    console.log("params", params);
    useEffect(() => {
        if (params.id) {
            reportStore.getDetailedReport(params.id).catch(e => console.error(e))
        } else {
            // navigate to 404
        }
    }, [])

    if (!reportStore.selectedReport) {
        return (
            <Pane>
                <Loader />
            </Pane>
        )
    }

    if (reportStore.selectedReport.status !== "SUCCESS") {
        const isFailed = reportStore.selectedReport.status === 'FAILED';
        return (
            <Pane>
                <ReportHeading detailedReport={reportStore.selectedReport} />
                <Pane>
                    <Text>Load test {isFailed ? 'failed' : 'still in progress'}, checkout sfn id: {reportStore.selectedReport.sfnExecutionId} for more details</Text>
                </Pane>
            </Pane>
        )
    }

    return (
        <Pane>
            <ReportHeading detailedReport={reportStore.selectedReport} />
            <LatencyChart detailedReports={[reportStore.selectedReport]} borderColors={['rgb(75, 192, 192)', 'rgb(75, 24, 240)', 'red', 'green', 'orange']} monotoneColors={[]} />
            <StatusCodeChart data={reportStore.selectedReport}/>
            <ConfigFile detailedReport={reportStore.selectedReport} />
        </Pane>
    );
}

export default LoadReport;

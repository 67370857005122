import {
    ApiRequestType,
    DetailedReport,
    GetConfigResponse,
    Iapi,
    ListConfigsResponse,
    ListReportsResponse,
    ListSecretsResponse,
    StartLoadTestRequest,
    StartLoadTestResponse,
    UploadConfigResponse
} from "./Iapi";
import {InvokeCommand, LambdaClient} from "@aws-sdk/client-lambda";
import {Auth} from "aws-amplify";
import {ICredentials} from "aws-amplify/lib/Common/types/types";

interface BaseRequest {
    type: ApiRequestType
}

interface GetDetailedReportRequest extends BaseRequest {
    reportId: string,
}

interface PaginatedRequest extends BaseRequest {
    nextToken?: string
}

interface UploadConfigRequest extends BaseRequest {
    configName: string
}

interface GetDetailedReportResult {
    report: DetailedReport
}

interface GetConfigRequest extends BaseRequest {
    configName: string,
}

interface DeleteConfigRequest extends BaseRequest {
    configName: string,
}

interface DeleteSecretRequest extends BaseRequest {
    secretName: string
}

interface AddSecretRequest extends BaseRequest {
    secretName: string
    token: string
}

interface CancelLoadTestApiRequest extends BaseRequest {
    id: string
}

class AwsApi implements Iapi {
    private lambdaFunctionName: string = process.env.ORG_NAME ? `load-test-api-${process.env.ORG_NAME.toLowerCase()}` : "load-test-api-preprod";
    private region: string = process.env.AWS_REGION ? process.env.AWS_REGION : "us-west-2"
    async getDetailedReport(reportId: string): Promise<DetailedReport> {
        const request: GetDetailedReportRequest = {
            type: ApiRequestType.getDetailedReport,
            reportId: reportId,
        }
        const response = await this.makeLambdaApiCall<GetDetailedReportRequest, GetDetailedReportResult>(request);
        return response.report;
    }

    async listReports(nextToken?: string): Promise<ListReportsResponse> {
        const request: PaginatedRequest = {
            type: ApiRequestType.listReports,
            nextToken
        }
        return await this.makeLambdaApiCall<PaginatedRequest, ListReportsResponse>(request)
    }

    async listConfigs(nextToken?: string): Promise<ListConfigsResponse> {
        const request: PaginatedRequest = {
            type: ApiRequestType.listConfigs,
            nextToken: nextToken
        }
        return await this.makeLambdaApiCall<PaginatedRequest, ListConfigsResponse>(request)
    }

    async uploadConfig(file: File): Promise<UploadConfigResponse> {
        const request: UploadConfigRequest = {
            type: ApiRequestType.uploadConfig,
            configName: file.name
        }
        return await this.makeLambdaApiCall<UploadConfigRequest, UploadConfigResponse>(request)
    }

    async getConfig(name: string): Promise<GetConfigResponse> {
        const request: GetConfigRequest = {
            type: ApiRequestType.getConfig,
            configName: name
        }

        return await this.makeLambdaApiCall<GetConfigRequest, GetConfigResponse>(request)
    }

    async deleteConfig(configName: string): Promise<void> {
        const request: DeleteConfigRequest = {
            type: ApiRequestType.deleteConfig,
            configName: configName
        }
        return await this.makeLambdaApiCall<DeleteConfigRequest, void>(request)
    }

    async listSecrets(nextToken?: string): Promise<ListSecretsResponse> {
        const request: PaginatedRequest = {
            type: ApiRequestType.listSecrets,
            nextToken: nextToken
        }
        return await this.makeLambdaApiCall<PaginatedRequest, ListSecretsResponse>(request)
    }

    async deleteSecret(secretName: string): Promise<void> {
        const request: DeleteSecretRequest = {
            type: ApiRequestType.deleteSecret,
            secretName
        }
        return await this.makeLambdaApiCall<DeleteSecretRequest, void>(request)
    }

    async addSecret(secretName: string, token: string): Promise<void> {
        const request: AddSecretRequest = {
            type: ApiRequestType.addSecret,
            secretName,
            token
        }
        return await this.makeLambdaApiCall<AddSecretRequest, void>(request)
    }

    async startLoadTest(r: StartLoadTestRequest): Promise<StartLoadTestResponse> {
        const request: StartLoadTestRequest = {
            type: ApiRequestType.runLoadTest,
            authTokenPath: r.authTokenPath,
            availabilityZoneId: r.availabilityZoneId,
            cacheName: r.cacheName,
            configName: r.configName,
            durationInMinutes: r.durationInMinutes || 60,
            instanceType: r.instanceType ?? "c6i.4xlarge",
            operatorUser: (await Auth.currentAuthenticatedUser()).username,
            region: r.region,
            loadTestName: r.loadTestName,
            accountWeAreTestingId: r.accountWeAreTestingId,
            accountWeAreTestingName: r.accountWeAreTestingName
        }
        return await this.makeLambdaApiCall<StartLoadTestRequest, StartLoadTestResponse>(request)
    }

    async cancelLoadTest(id: string): Promise<void> {
        const request: CancelLoadTestApiRequest = {
            type: ApiRequestType.cancelLoadTest,
            id: id
        }
        return await this.makeLambdaApiCall<CancelLoadTestApiRequest, void>(request)
    }

    private async makeLambdaApiCall<RequestType, ResponseType>(payload: RequestType): Promise<ResponseType> {
        let creds: ICredentials;
        try {
            creds = await Auth.currentCredentials()
        } catch(e) {
            await Auth.signOut()
            throw new Error(`failed to authenticate, signing out: ${e}`)
        }
        const lambdaClient = new LambdaClient({
            region: this.region,
            credentials: creds
        })
        try {
            const request = new InvokeCommand({
                FunctionName: this.lambdaFunctionName,
                Payload: new TextEncoder().encode(JSON.stringify(payload)),
            })
            const resp = await lambdaClient.send(request)
            console.log("response", resp)
            // makes sure api call to inovke lambda was successful
            if (resp.StatusCode !== 200) {
                throw new Error(`failed to load perf tests, status: ${resp.StatusCode}, error: ${resp.FunctionError}`)
            }
            const decodedResponse = JSON.parse(new TextDecoder().decode(resp.Payload!))
            console.log("decoded response", decodedResponse)
            // makes sure internally our lambda call was successful
            if (decodedResponse.statusCode && decodedResponse.statusCode > 299) {
                throw new Error(`api call failed status: ${decodedResponse.statusCode}, message: ${decodedResponse.message}`)
            }

            return decodedResponse as ResponseType
        } finally {
            lambdaClient.destroy()
        }
    }
}

export const awsApi = new AwsApi();

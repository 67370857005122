import React from 'react';
import {Pane} from "evergreen-ui";
import {DetailedReport} from "../../api";
import {generateLatencyLineChartProps} from "../../lib/common";
import {Line} from "react-chartjs-2";

type Props = {
    detailedReports: Array<DetailedReport>;
    borderColors: Array<string>;
    monotoneColors: Array<string>;
}

function LatencyChart(props: Props) {
    const lineChartProps = generateLatencyLineChartProps(props.detailedReports, props.borderColors, props.monotoneColors)
    return <Pane marginTop={32} display='flex'>
        <Pane flex={4}>
            <Line data={lineChartProps} options={
                {
                    plugins:{
                        zoom: {
                            zoom: {
                                wheel: {
                                    enabled: true,
                                    speed: 0.04
                                },
                                pinch: {
                                    enabled: true,
                                },
                                mode: 'x',
                            }
                        }
                    },
                    responsive: true,
                    scales: {
                        x: { display: true, type: "linear", title: { display: true, text: "10s"} },
                        y: { display: true, type: "linear", title: { display: true, text: "Latency (ms)"} }
                    }
                }
            }/>
        </Pane>
        <Pane flex={1} />
    </Pane>
}

export default LatencyChart;

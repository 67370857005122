import React, { useState } from "react"
import { createContainer } from "unstated-next"
import {toaster} from "evergreen-ui";
import {api} from '../api';

function useSecretStore() {
    const [isFetching, setIsFetching] = useState(false);
    const [secrets, setSecrets] = useState<string[]>([]);
    const [nextSecretsToken, setNextSecretsToken] = useState<string | undefined>(undefined);

    const listSecrets = async (fetchNext?: boolean) => {
        setIsFetching(true);
        try {
            if (fetchNext) {
                const resp = await api.listSecrets(nextSecretsToken)
                setSecrets((currSecrets) => [...currSecrets, ...resp.secrets])
                setNextSecretsToken(resp.nextToken)
            } else {
                const resp = await api.listSecrets();
                setSecrets(resp.secrets)
                setNextSecretsToken(resp.nextToken)
            }
        } catch (e) {
            toaster.danger(`failed to list secrets: ${e}`)
        } finally {
            setIsFetching(false)
        }
    }

    const deleteSecret = async (secretName: string) => {
        setIsFetching(true);
        try {
            await api.deleteSecret(secretName);
            setSecrets(currSecrets => currSecrets.filter(it => it !== secretName))
        } catch (e) {
            toaster.danger(`failed to delete secret: ${e}`)
        } finally {
            setIsFetching(false)
        }
    }

    const addSecret = async (secretName: string, token: string) => {
        setIsFetching(true);
        try {
            await api.addSecret(secretName, token);
            setSecrets(currSecrets => [...currSecrets, secretName])
        } catch (e) {
            toaster.danger(`failed to delete secret: ${e}`)
        } finally {
            setIsFetching(false)
        }
    }

    const hasMoreSecrets = !!nextSecretsToken
    return { secrets, isFetching, listSecrets, hasMoreSecrets, deleteSecret, addSecret }
}

export const SecretStore = createContainer(useSecretStore)

import React, {useEffect} from 'react';
import {
    Pane,
    Heading,
} from "evergreen-ui";
import {
    useParams,
} from "react-router-dom";
import {ComparisonStore} from "../../store/comparison-store";
import {Loader} from "@aws-amplify/ui-react";
import ReportHeading from "./ReportHeading";
import ReportSummaryTable from "./ReportSummaryTable";
import LatencyChart from "./LatencyChart";
import ConfigFile from "./ConfigFile";

type Params = {
    reportId1: string;
    reportId2: string;
}

function ReportComparison() {
    const comparisonStore = ComparisonStore.useContainer();
    const params = useParams<Params>();

    useEffect(() => {
        comparisonStore.compareReports({
            reportId1: params.reportId1!,
            reportId2: params.reportId2!
        }).catch(e => console.error(e))
    }, [])

    if (comparisonStore.isFetching || !comparisonStore.comparedReports?.report1 || !comparisonStore.comparedReports?.report2) {
        return (
            <Pane>
                <Loader />
            </Pane>
        )
    }

    const color1 = "blue"
    const color2 = "orange"

    return (
        <Pane marginTop={6}>
            <Pane>
                <Heading size={600}>REPORT COMPARISON</Heading>
            </Pane>

            <Pane marginTop={16} display='flex'>
                <ReportHeading detailedReport={comparisonStore.comparedReports!.report1} color={color1} />
                <ReportHeading detailedReport={comparisonStore.comparedReports!.report2} color={color2} />
            </Pane>

            <LatencyChart detailedReports={[comparisonStore.comparedReports.report1, comparisonStore.comparedReports.report2]} borderColors={[]} monotoneColors={[color1, color2]} />

            <ReportSummaryTable
                detailedReports={[comparisonStore.comparedReports.report1, comparisonStore.comparedReports.report2]}
                colors={[color1, color2]}
            />
            <Pane>
                <Heading size={600}>
                    Configs
                </Heading>
                <Pane display={'flex'} flexDirection={'row'} flexWrap={'nowrap'}>
                    <ConfigFile detailedReport={comparisonStore.comparedReports?.report1} color={color1} />
                    <Pane width={8} />
                    <ConfigFile detailedReport={comparisonStore.comparedReports?.report2} color={color2} />
                </Pane>
            </Pane>
        </Pane>
    );
}

export default ReportComparison;

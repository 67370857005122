import {useState} from "react"
import {createContainer} from "unstated-next"
import {toaster} from "evergreen-ui";
import {api, DetailedReport} from '../api';
import {ApiRequestType} from "../api/Iapi";

export type StoreStartLoadTestRequest = {
    accountWeAreTestingId?: string,
    accountWeAreTestingName?: string,
    authTokenPath: string,
    availabilityZoneId: string,
    cacheName: string,
    configName: string,
    durationInMinutes?: number,
    instanceType?: string,
    loadTestName: string,
    region: string,
}

type ResponseWithSuccessStatus = {
    isSuccess: boolean
}

function useLoadStore() {
    const [isFetching, setIsFetching] = useState(false);

    const cancelLoadTest = async (id: string): Promise<ResponseWithSuccessStatus> => {
        let isSuccess = true;
        setIsFetching(true);
        try {
            const resp = await api.cancelLoadTest(id)
            toaster.success(`cancelled load test: ${id}`)
        } catch (e) {
            isSuccess = false;
            toaster.danger(`failed to cancel load test: ${e}`)
        } finally {
            setIsFetching(false)
        }
        return { isSuccess }
    }

    const recreateLoadTest = async (report: DetailedReport): Promise<ResponseWithSuccessStatus> => {
        const request: StoreStartLoadTestRequest = {
            accountWeAreTestingId: report.accountWeAreTestingId,
            accountWeAreTestingName: report.accountWeAreTestingName,
            authTokenPath: report.authTokenPath,
            availabilityZoneId: report.availabilityZoneId,
            cacheName: report.cacheName,
            configName: report.configName,
            durationInMinutes: report.durationInMinutes,
            instanceType: report.instanceType,
            loadTestName: report.loadTestName,
            region: report.region
        }

        return await startLoadTest(request);
    }

    const startLoadTest = async (request: StoreStartLoadTestRequest): Promise<ResponseWithSuccessStatus> => {
        let isSuccess = true;
        setIsFetching(true);
        try {
            const resp = await api.startLoadTest({
                accountWeAreTestingId: request.accountWeAreTestingId,
                accountWeAreTestingName: request.accountWeAreTestingName,
                authTokenPath: request.authTokenPath,
                availabilityZoneId: request.availabilityZoneId,
                cacheName: request.cacheName,
                configName: request.configName,
                durationInMinutes: request.durationInMinutes,
                instanceType: request.instanceType,
                loadTestName: request.loadTestName,
                region: request.region,
                type: ApiRequestType.runLoadTest,
            })
            toaster.success(`started load test: ${resp.loadTestId}`)
        } catch (e) {
            isSuccess = false;
            toaster.danger(`failed to start load test: ${e}`)
        } finally {
            setIsFetching(false)
        }
        return { isSuccess }
    }

    return { startLoadTest, isFetching, cancelLoadTest, recreateLoadTest }
}

export const LoadStore = createContainer(useLoadStore)

import React, {ChangeEvent, useState} from 'react'
import {
    Pane,
    Button, TextInputField
} from 'evergreen-ui'
import {LoadStore} from "../../store/load-store";
import {useNavigate, useParams} from "react-router-dom";
import {Loader} from "@aws-amplify/ui-react";

type Params = {
    id?: string;
}

export function CancelPane() {
    const navigate = useNavigate();
    const params = useParams<Params>();
    const loadStore = LoadStore.useContainer();
    const [loadTestId, setLoadTestId] = useState<string>(params.id ?? "")

    const cancelLoadTest = async () => {
        const resp = await loadStore.cancelLoadTest(loadTestId);
        if (resp.isSuccess) {
            navigate("/reports", { replace: true })
        }
    }

    return (
        <Pane>
            <Pane display={'flex'} maxWidth={800} flexWrap={'wrap'} marginTop={12}>
                <TextInputField
                    value={loadTestId}
                    id={"load-test-id"}
                    width={200}
                    required={true}
                    label="Load Test Id"
                    placeholder="cf779026-aa8a-46ae-88ad-71ae2cc454c4"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setLoadTestId(event.target.value)}
                    marginRight={8}
                />
            </Pane>

            {loadStore.isFetching ?
                <Loader /> :
                <Button
                    onClick={
                        async () => {
                            await cancelLoadTest();
                        }
                    }
                    disabled={loadTestId.length === 0}
                >
                    Cancel
                </Button>
            }
        </Pane>
    )
}


import React, {ChangeEvent, useState} from 'react'
import {
    Pane,
    Button,
    Popover, TextInput, TextInputField,
} from 'evergreen-ui'
import {SecretStore} from "../../store/secret-store";
import {PaginatedListOfItems} from "../shared/PaginatedListOfItems";


export function SecretsList() {
    const secretsStore = SecretStore.useContainer();
    const [newSecretName, setNewSecretName] = useState("")
    const [newToken, setNewToken] = useState("")

    const onCreateNewSecret = async () => {
        await secretsStore.addSecret(newSecretName, newToken)
    }

    return (
        <Pane>
            <PaginatedListOfItems
                onListItems={secretsStore.listSecrets}
                items={secretsStore.secrets}
                hasMoreItems={secretsStore.hasMoreSecrets}
                isFetching={secretsStore.isFetching}
                header={'SECRETS'}
                buttonLeftOfPagination={
                    <Popover content={({ close }) => (
                            <Pane width={300} height={300} display="flex" alignItems="center" justifyContent="center" flexDirection="column" padding={'20px'}>
                                <TextInputField
                                    width={200}
                                    description="Must start with 'perftesting/'"
                                    label="Secret Name"
                                    placeholder="perftesting/alpha/mycache"
                                    required={true}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => setNewSecretName(event.target.value)}
                                    isInvalid={!newSecretName.startsWith("perftesting/")}
                                />
                                <TextInputField
                                    width={200}
                                    required={true}
                                    label="Token"
                                    placeholder="eyJhbGciOiJIUzUxMiJ9...."
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => setNewToken(event.target.value)}
                                />
                                <Button onClick={
                                    async () => {
                                        await onCreateNewSecret();
                                        close();
                                    }
                                }>Submit</Button>
                            </Pane>
                        )
                    }>
                        <Button>
                            Upload Secret
                        </Button>
                    </Popover>
                }
                onDelete={secretsStore.deleteSecret}
            />
        </Pane>
    )
}

